import { Component, Input, OnInit } from '@angular/core';
import { ShipmentOverview } from 'src/app/_model/shipment-overview';
import { ShipmentStatus } from 'src/app/_model/shipment-status.enum';

@Component({
  selector: 'app-shipment-status',
  templateUrl: './shipment-overview.component.html',
  styleUrls: ['./shipment-overview.component.scss']
})
export class ShipmentOverviewComponent implements OnInit {

  @Input() shipmentOverview: ShipmentOverview;

  constructor() {
  }

  ngOnInit() {
  }

}
