import { Component, OnInit } from '@angular/core';
import { Statistic } from 'src/app/_model/statistic';
import { StatisticsService } from 'src/app/_service/statistics.service';

export enum StatisticPeriodEnum {
  all = 'Gesamt',
  year = 'Jahr',
  quarter = 'Quartal',
  month = 'Monat',
}

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {
  statisticsPeriodEnum = StatisticPeriodEnum;
  statisticsPeriod: StatisticPeriodEnum = this.statisticsPeriodEnum.all;
  statistics: Array<Statistic>;
  isLoading = true;
  errorMessage: string;

  constructor(private statisticsService: StatisticsService) {
  }

  ngOnInit() {
    this.loadStatistics();
  }

  loadStatistics() {
    this.statistics = [];
    this.isLoading = true;
    let period: string;
    let amount: number;
    switch (this.statisticsPeriod) {
      case StatisticPeriodEnum.all:
        period = 'all';
        amount = undefined;
        break;
      case StatisticPeriodEnum.month:
        period = 'month';
        amount = 12;
        break;
      case StatisticPeriodEnum.quarter:
        period = 'quarter';
        amount = 6;
        break;
      case StatisticPeriodEnum.year:
        period = 'year';
        amount = 4;
        break;
    }

    this.statisticsService.getStatistics(period, amount).then(statistics => {
      if (statistics) {
        this.statistics = statistics;
        this.isLoading = false;
      }
    }).catch(error => {
      this.errorMessage = 'Bei der Abfrage der Statistik ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.';
    }).finally(() => this.isLoading = false);
  }

  print() {
    window.print();
  }

}
