import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-svg-step4-inactive',
  template: `
      <svg width="24" height="25" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.3877 19.5169C17.8013 19.5169 17.2441 19.7466 16.8239 20.162C16.4036 20.5823 16.169 21.1296 16.169 21.7161C16.169 22.3025 16.3987 22.8499 16.8239 23.2701C17.249 23.6855 17.8013 23.9152 18.3877 23.9152C19.5899 23.9152 20.5673 22.9281 20.5673 21.7161C20.5673 20.5041 19.5899 19.5169 18.3877 19.5169ZM18.3877 22.9378C17.7133 22.9378 17.1464 22.3807 17.1464 21.7161C17.1464 21.0515 17.7133 20.4943 18.3877 20.4943C19.0523 20.4943 19.5899 21.0417 19.5899 21.7161C19.5899 22.3905 19.0523 22.9378 18.3877 22.9378ZM6.45368 19.5169C5.86724 19.5169 5.31012 19.7466 4.88984 20.162C4.46956 20.5823 4.23499 21.1296 4.23499 21.7161C4.23499 22.3025 4.46467 22.8499 4.88984 23.2701C5.31501 23.6855 5.86724 23.9152 6.45368 23.9152C7.65588 23.9152 8.63328 22.9281 8.63328 21.7161C8.63328 20.5041 7.65588 19.5169 6.45368 19.5169ZM6.45368 22.9378C5.77927 22.9378 5.21238 22.3807 5.21238 21.7161C5.21238 21.0515 5.77927 20.4943 6.45368 20.4943C7.11831 20.4943 7.65588 21.0417 7.65588 21.7161C7.65588 22.3905 7.11831 22.9378 6.45368 22.9378Z" [attr.fill]="fill"/>
          <path d="M18.9546 10.2141C18.8666 10.117 18.7493 10.0656 18.6272 10.0656H16.1201C15.8514 10.0656 15.6314 10.3226 15.6314 10.6367V15.3199C15.6314 15.634 15.8514 15.891 16.1201 15.891H20.0981C20.3669 15.891 20.5868 15.634 20.5868 15.3199V12.1845C20.5868 12.0245 20.5282 11.8703 20.4256 11.7618L18.9546 10.2141ZM19.6094 14.7488H16.6088V11.2021H18.4366L19.6094 12.4358V14.7488Z" [attr.fill]="fill"/>
          <path d="M3.19406 19.4776H2.21177V17.9585V9.54589V5.93643H13.6864V19.4776H9.67909C9.41031 19.4776 9.19039 19.7346 9.19039 20.0488C9.19039 20.3629 9.41031 20.6199 9.67909 20.6199H15.6461C15.9149 20.6199 16.1348 20.3629 16.1348 20.0488C16.1348 19.7346 15.9149 19.4776 15.6461 19.4776H14.6638V8.56357H18.6027L21.789 11.6476L21.7548 19.4662H21.2466C20.9778 19.4662 20.7579 19.7232 20.7579 20.0373C20.7579 20.3514 20.9778 20.6085 21.2466 20.6085H22.2386C22.5074 20.6085 22.7273 20.3572 22.7273 20.043L22.7664 11.3849C22.7616 11.2136 22.698 11.0479 22.5856 10.9394L19.0865 7.55269C18.9986 7.46702 18.8911 7.42133 18.7738 7.42133H14.6589V5.36531C14.6589 5.05119 14.439 4.79419 14.1702 4.79419H1.72307C1.45429 4.79419 1.23438 5.05119 1.23438 5.36531V9.54589V17.9585V20.0488C1.23438 20.3629 1.45429 20.6199 1.72307 20.6199H3.19406C3.46284 20.6199 3.68276 20.3629 3.68276 20.0488C3.68276 19.7346 3.46284 19.4776 3.19406 19.4776Z" [attr.fill]="fill"/>
      </svg>
  `
})
export class Step4InactiveComponent {
  @Input() fill: string;
}
