import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-svg-step5-active',
  template: `
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M23.1334 16.8718C23.1334 20.3296 20.3303 23.1328 16.8724 23.1328C13.4145 23.1328 10.6113 20.3296 10.6113 16.8718C10.6113 13.4139 13.4145 10.6107 16.8724 10.6107C20.3303 10.6107 23.1334 13.4139 23.1334 16.8718ZM14.0575 17.4393C13.8538 17.2356 13.8538 16.9052 14.0575 16.7014C14.2613 16.4977 14.5916 16.4977 14.7954 16.7014L15.995 17.901L19.3154 14.5806C19.5191 14.3768 19.8495 14.3768 20.0533 14.5806C20.257 14.7844 20.257 15.1147 20.0533 15.3185L16.3658 19.006L16.3639 19.0078C16.1601 19.2116 15.8298 19.2116 15.626 19.0078L14.0575 17.4393Z" [attr.fill]="fill"/>
          <path d="M22.6859 9.4485L11.8853 0.546347C11.7058 0.3967 11.4451 0.396636 11.2655 0.5463L0.609568 9.42633C0.499391 9.5181 0.435547 9.65426 0.435547 9.79804V22.2298C0.435547 22.4971 0.6522 22.7138 0.919574 22.7138H12.8033C10.9686 21.4268 9.76929 19.2957 9.76929 16.8844C9.76929 12.9546 12.9551 9.7688 16.8849 9.7688C19.3948 9.7688 21.6011 11.0683 22.8679 13.0311V9.83433C22.8679 9.685 22.8012 9.54348 22.6859 9.4485Z" [attr.fill]="fill"/>
      </svg>
  `
})
export class Step5ActiveComponent {
  @Input() fill: string;
}
