import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-svg-step1-inactive',
  template: `
      <svg width="24" height="25" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7356 2.25123C13.7629 2.32818 13.8357 2.3796 13.9173 2.3796H20.137C20.4032 2.3796 20.6191 2.59541 20.6191 2.86162V23.2932C20.6191 23.5594 20.4032 23.7752 20.137 23.7752H3.86288C3.59667 23.7752 3.38086 23.5594 3.38086 23.2932V2.86162C3.38086 2.59541 3.59667 2.3796 3.86288 2.3796H10.0826C10.1642 2.3796 10.237 2.32818 10.2643 2.25123C10.5177 1.53652 11.2007 1.02393 12 1.02393C12.7992 1.02393 13.4822 1.53652 13.7356 2.25123ZM11.1293 2.86463C11.1293 3.13085 10.9135 3.34967 10.6473 3.34967H8.89997C8.63376 3.34967 8.41796 3.56547 8.41796 3.83168V4.60894C8.41796 4.87515 8.63376 5.09096 8.89997 5.09096H15.0999C15.3662 5.09096 15.582 4.87515 15.582 4.60894V3.83168C15.582 3.56547 15.3662 3.34967 15.0999 3.34967H13.3526C13.0864 3.34967 12.8706 3.13085 12.8706 2.86463C12.8706 2.38446 12.4801 1.99399 12 1.99399C11.5198 1.99399 11.1293 2.38446 11.1293 2.86463ZM4.35092 3.34967V22.8051H19.649V3.34967H16.552V5.579C16.552 5.84521 16.3362 6.06102 16.07 6.06102H7.92991C7.6637 6.06102 7.44789 5.84521 7.44789 5.579V3.34967H4.35092Z" [attr.fill]="fill"/>
          <path d="M6.21573 9.90235C6.21573 9.63614 6.43154 9.42033 6.69775 9.42033H17.3022C17.5684 9.42033 17.7842 9.63614 17.7842 9.90235C17.7842 10.1686 17.5684 10.3844 17.3022 10.3844H6.69775C6.43154 10.3844 6.21573 10.1686 6.21573 9.90235Z" [attr.fill]="fill"/>
          <path d="M6.21573 13.7585C6.21573 13.4923 6.43154 13.2765 6.69775 13.2765H17.3022C17.5684 13.2765 17.7842 13.4923 17.7842 13.7585C17.7842 14.0247 17.5684 14.2405 17.3022 14.2405H6.69775C6.43154 14.2405 6.21573 14.0247 6.21573 13.7585Z" [attr.fill]="fill"/>
          <path d="M6.21573 17.6147C6.21573 17.3484 6.43154 17.1326 6.69775 17.1326H17.3022C17.5684 17.1326 17.7842 17.3484 17.7842 17.6147C17.7842 17.8809 17.5684 18.0967 17.3022 18.0967H6.69775C6.43154 18.0967 6.21573 17.8809 6.21573 17.6147Z" [attr.fill]="fill"/>
      </svg>
  `
})
export class Step1InactiveComponent {
  @Input() fill: string;
}
