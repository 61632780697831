export class User {
  username: string;
  department: string;
  roles: Array<string>;

  constructor(username: string, department: string, roles: Array<string>) {
    this.username = username;
    this.department = department;
    this.roles = roles;
  }
}
