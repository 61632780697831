import { ShipmentStatus } from 'src/app/_model/shipment-status.enum';

export class ShipmentOverview {
  id: number;
  receiver: string;
  shipmentNumber: string;
  status: ShipmentStatus;
  description: string;
  color: string;
  date: Date;
}
