import { Pipe, PipeTransform } from '@angular/core';
import { DateConverterService } from 'src/app/_service/date-converter.service';

@Pipe({
  name: 'longDate'
})
export class LongDatePipe implements PipeTransform {

  constructor(private dateConverterService: DateConverterService) {}

  transform(date: Date): string {
    if (date === undefined || date === null) {
      return '';
    }
    return this.dateConverterService.convertToString(date);
  }

}
