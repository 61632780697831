import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-svg-step2-active',
  template: `
      <svg width="24" height="25" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0)">
              <path d="M8.02955 3.64081C7.94745 3.39017 8.08409 3.12043 8.33473 3.03834L13.0227 1.50286C13.2733 1.42076 13.5431 1.5574 13.6251 1.80804L15.1398 6.43252C15.2219 6.68316 15.0853 6.95289 14.8347 7.03499L10.1467 8.57047C9.89607 8.65257 9.62634 8.51593 9.54424 8.26529L8.02955 3.64081Z"
                    [attr.fill]="fill"/>
              <path d="M11.3939 18.4504L6.60769 3.94439C6.20475 2.72311 4.88296 2.05705 3.66154 2.46006C2.44011 2.86308 1.77426 4.18493 2.1772 5.40622C2.26739 5.67952 2.5621 5.828 2.8354 5.73784C2.99514 5.66778 3.28509 5.43805 3.16702 5.07964C2.94403 4.40377 3.31223 3.67289 3.98814 3.44988C4.66402 3.22688 5.3949 3.59509 5.61791 4.271L10.2897 18.4304C10.6628 18.3761 11.0351 18.3845 11.3939 18.4504Z"
                    [attr.fill]="fill"/>
              <path d="M13.4827 19.7191L21.2551 17.1547C21.5283 17.0645 21.8231 17.2129 21.9133 17.4863C22.0035 17.7596 21.855 18.0543 21.5817 18.1445L13.9933 20.6483C13.8695 20.3054 13.6956 19.9939 13.4827 19.7191Z"
                    [attr.fill]="fill"/>
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M10.006 19.4668C11.2997 19.0399 12.6945 19.7427 13.1213 21.0364C13.5482 22.3301 12.8454 23.7249 11.5518 24.1518C10.258 24.5786 8.86325 23.8759 8.43638 22.5822C8.0095 21.2884 8.71227 19.8937 10.006 19.4668ZM11.2104 23.1675C10.4509 23.4181 9.63195 23.0055 9.38135 22.2459C9.13074 21.4864 9.54333 20.6674 10.3029 20.4169C11.0625 20.1663 11.8814 20.5788 12.132 21.3384C12.3826 22.0979 11.97 22.9168 11.2104 23.1675Z"
                    [attr.fill]="fill"/>
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M21.902 15.0754L19.1957 6.8729C19.1382 6.69866 18.9503 6.60405 18.7761 6.66147L10.2582 9.47192C10.0839 9.52941 9.98929 9.71725 10.0468 9.89149L12.7531 18.0939C12.8106 18.2682 12.9984 18.3628 13.1726 18.3053L21.6906 15.4949C21.8648 15.4374 21.9595 15.2496 21.902 15.0754ZM17.1023 14.6254L16.0805 11.5283L17.2125 12.1513C17.3867 12.2473 17.6057 12.1836 17.7014 12.0093C17.797 11.8352 17.7335 11.6166 17.5595 11.5208L15.6844 10.4892C15.4414 10.3555 15.1359 10.4563 15.0202 10.7084L14.1276 12.6532C14.0448 12.8336 14.1238 13.0471 14.3042 13.1301C14.4849 13.2133 14.6988 13.1341 14.7817 12.9533L15.3207 11.7783L16.3428 14.876C16.412 15.0857 16.6381 15.1997 16.8479 15.1305C17.0576 15.0613 17.1715 14.8351 17.1023 14.6254Z"
                    [attr.fill]="fill"/>
          </g>
          <defs>
              <clipPath id="clip0">
                  <rect width="24" height="24" fill="white" transform="translate(0 0.8125)"/>
              </clipPath>
          </defs>
      </svg>


  `
})
export class Step2ActiveComponent {
  @Input() fill: string;
}
