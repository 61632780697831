import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-svg-step5-inactive',
  template: `
      <svg width="24" height="25" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0)">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M22.6019 10.5077C22.4309 10.7129 22.1257 10.7406 21.9203 10.5695L11.5744 1.94789L1.40262 10.4244V22.1454H11.1229C11.3903 22.1454 11.6069 22.3621 11.6069 22.6294C11.6069 22.8968 11.3903 23.1135 11.1229 23.1135H0.918597C0.651223 23.1135 0.43457 22.8968 0.43457 22.6294V10.1977C0.43457 10.0539 0.498414 9.91776 0.608592 9.82598L11.2646 0.945958C11.4441 0.796294 11.7048 0.796358 11.8843 0.946005L22.5401 9.82591C22.7454 9.99691 22.7732 10.3021 22.6019 10.5077Z"
                    [attr.fill]="fill"/>
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M16.8722 22.4889C19.7538 22.4889 22.0898 20.1529 22.0898 17.2714C22.0898 14.3898 19.7538 12.0538 16.8722 12.0538C13.9907 12.0538 11.6547 14.3898 11.6547 17.2714C11.6547 20.1529 13.9907 22.4889 16.8722 22.4889ZM16.8722 23.5324C20.3301 23.5324 23.1333 20.7293 23.1333 17.2714C23.1333 13.8135 20.3301 11.0103 16.8722 11.0103C13.4143 11.0103 10.6112 13.8135 10.6112 17.2714C10.6112 20.7293 13.4143 23.5324 16.8722 23.5324Z"
                    [attr.fill]="fill"/>
              <path d="M14.0573 17.1011C13.8535 17.3049 13.8535 17.6352 14.0573 17.839L15.6258 19.4075C15.8296 19.6113 16.1599 19.6113 16.3637 19.4075L16.3655 19.4056L20.053 15.7181C20.2568 15.5144 20.2568 15.184 20.053 14.9803C19.8493 14.7765 19.5189 14.7765 19.3152 14.9803L15.9947 18.3007L14.7952 17.1011C14.5914 16.8974 14.2611 16.8974 14.0573 17.1011Z"
                    [attr.fill]="fill"/>
          </g>
          <defs>
              <clipPath id="clip0">
                  <rect width="24" height="24" fill="white" transform="translate(0 0.399658)"/>
              </clipPath>
          </defs>
      </svg>
  `
})
export class Step5InactiveComponent {
  @Input() fill: string;
}
