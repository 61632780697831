import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-svg-step4-active',
  template: `
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.3877 19.1173C17.8013 19.1173 17.2441 19.347 16.8239 19.7624C16.4036 20.1826 16.169 20.73 16.169 21.3164C16.169 21.9029 16.3987 22.4502 16.8239 22.8705C17.249 23.2859 17.8013 23.5156 18.3877 23.5156C19.5899 23.5156 20.5673 22.5284 20.5673 21.3164C20.5673 20.1045 19.5899 19.1173 18.3877 19.1173ZM18.3877 22.5382C17.7133 22.5382 17.1464 21.9811 17.1464 21.3164C17.1464 20.6518 17.7133 20.0947 18.3877 20.0947C19.0523 20.0947 19.5899 20.642 19.5899 21.3164C19.5899 21.9908 19.0523 22.5382 18.3877 22.5382ZM6.45368 19.1173C5.86724 19.1173 5.31012 19.347 4.88984 19.7624C4.46956 20.1826 4.23499 20.73 4.23499 21.3164C4.23499 21.9029 4.46467 22.4502 4.88984 22.8705C5.31501 23.2859 5.86724 23.5156 6.45368 23.5156C7.65588 23.5156 8.63328 22.5284 8.63328 21.3164C8.63328 20.1045 7.65588 19.1173 6.45368 19.1173ZM6.45368 22.5382C5.77927 22.5382 5.21238 21.9811 5.21238 21.3164C5.21238 20.6518 5.77927 20.0947 6.45368 20.0947C7.11831 20.0947 7.65588 20.642 7.65588 21.3164C7.65588 21.9908 7.11831 22.5382 6.45368 22.5382Z" [attr.fill]="fill"/>
          <path d="M18.9546 9.81444C18.8666 9.71735 18.7493 9.66595 18.6272 9.66595H16.1201C15.8514 9.66595 15.6314 9.92296 15.6314 10.2371V14.9202C15.6314 15.2344 15.8514 15.4914 16.1201 15.4914H20.0981C20.3669 15.4914 20.5868 15.2344 20.5868 14.9202V11.7848C20.5868 11.6249 20.5282 11.4707 20.4256 11.3622L18.9546 9.81444ZM19.6094 14.3491H16.6088V10.8025H18.4366L19.6094 12.0361V14.3491Z" [attr.fill]="fill"/>
          <path d="M1.23438 19.6491C1.23438 19.9632 1.45429 20.2202 1.72307 20.2202H3.11964C3.25194 19.6831 3.57067 19.1852 4.0435 18.7917C4.67925 18.2626 5.5415 17.9653 6.44057 17.9653C7.33965 17.9653 8.2019 18.2626 8.83764 18.7917C9.31048 19.1852 9.62921 19.6831 9.76151 20.2202H15.6461C15.9149 20.2202 16.1348 19.9632 16.1348 19.6491C16.1348 19.335 15.9149 19.078 15.6461 19.078H14.6638V8.16391H18.6027L21.789 11.248L21.7548 19.0666H21.2466C20.9778 19.0666 20.7579 19.3236 20.7579 19.6377C20.7579 19.9518 20.9778 20.2088 21.2466 20.2088H22.2386C22.5074 20.2088 22.7273 19.9575 22.7273 19.6434L22.7664 10.9852C22.7616 10.8139 22.698 10.6483 22.5856 10.5398L19.0865 7.15303C18.9986 7.06736 18.8911 7.02168 18.7738 7.02168H14.6589V4.96565C14.6589 4.65153 14.439 4.39453 14.1702 4.39453H1.72307C1.45429 4.39453 1.23438 4.65153 1.23438 4.96565V19.6491Z" [attr.fill]="fill"/>
      </svg>

  `
})
export class Step4ActiveComponent {
  @Input() fill: string;
}
