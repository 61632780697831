import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserSession } from 'src/app/_model/user-session';
import { AuthenticationService } from 'src/app/_service/authentication.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private authenticationService: AuthenticationService;

  url = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  public async post(endpoint: string, body: any, reqOpts?: any): Promise<any> {
    await this.authenticationService.refreshSessionIfNecessary();
    reqOpts = this.mergeReqOpts(reqOpts);
    return this.http.post(this.url + endpoint, body, reqOpts).toPromise().catch(error => {
      if (error.status === 401 && this.authenticationService.isLoggedIn()) {
        this.authenticationService.logout();
      }
      throw error;
    });
  }

  public async get(endpoint: string, reqOpts?: any): Promise<any> {
    await this.authenticationService.refreshSessionIfNecessary();
    reqOpts = this.mergeReqOpts(reqOpts);
    return this.http.get(this.url + endpoint, reqOpts).toPromise().catch(error => {
      if (error.status === 401 && this.authenticationService.isLoggedIn()) {
        this.authenticationService.logout();
      }
      throw error;
    });
  }

  mergeReqOpts(reqOpts: any) {
    if (!reqOpts) {
      reqOpts = {};
    }
    if (!reqOpts.headers) {
      reqOpts.headers = {};
    }

    reqOpts.headers['Content-Type'] = 'application/json';

    if (localStorage.getItem('currentUserSession')) {
      reqOpts.headers.Authorization =  'Bearer ' + (JSON.parse(localStorage.getItem('currentUserSession')) as UserSession).jwtToken;
    }

    return reqOpts;
  }

  setAuthenticationService(authenticationService: AuthenticationService) {
    this.authenticationService = authenticationService;
  }

}
