import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AlreadySignedInGuard} from 'src/app/_guards/already-signed-in.guard';
import {AuthGuard} from 'src/app/_guards/auth.guard';
import {ImprintComponent} from 'src/app/imprint/imprint.component';
import {LoginComponent} from 'src/app/login/login.component';
import {PrivacyComponent} from 'src/app/privacy/privacy.component';
import {ShipmentDetailComponent} from 'src/app/shipment-detail/shipment-detail.component';
import {ShipmentsComponent} from 'src/app/shipments/shipments.component';
import {StatisticsComponent} from 'src/app/statistics/statistics.component';
import {WelcomeComponent} from 'src/app/welcome/welcome.component';


const routes: Routes = [
  { path: 'imprint', component: ImprintComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'statistics', component: StatisticsComponent, canActivate: [ AuthGuard ] },
  { path: 'shipment-detail/:shipmentNumber', component: ShipmentDetailComponent, canActivate: [ AuthGuard ] },
  { path: 'shipment-tracking/:shipmentToken', component: ShipmentDetailComponent },
  { path: 'shipments', component: ShipmentsComponent, canActivate: [ AuthGuard ] },
  { path: 'login', component: LoginComponent, canActivate: [ AlreadySignedInGuard ] },
  { path: '**', component: WelcomeComponent, canActivate: [ AlreadySignedInGuard ] }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {
}
