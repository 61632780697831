import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User } from 'src/app/_model/user';
import { UserSession } from 'src/app/_model/user-session';
import { ApiService } from 'src/app/_service/api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private jwtHelperService = new JwtHelperService();

  constructor(private router: Router, private apiService: ApiService, private http: HttpClient) {
    apiService.setAuthenticationService(this);
  }

  private authenticate(prescibersNumber: string, password: string): Promise<void | UserSession> {
    const result = this.apiService.post('api/v1.0/login', { username: prescibersNumber, password });
    return result.then(data => {
      if (data.success) {
        return {
          jwtToken: data.data.token,
          refreshToken: data.data.refreshToken,
          user: new User(data.data.username, data.data.department, data.data.roles)
        } as UserSession;
      }
    }).catch(error => {
      if (error.status === 401) {
        throw new Error('Eingegebene Verordner Nr. oder Passwort ist falsch.');
      } else {
        throw new Error('Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.');
      }
    });
  }

  login(prescibersNumber: string, password: string, remainSignedIn: boolean): Promise<boolean | void> {
    return this.authenticate(prescibersNumber, password).then(value => {
      if (value) {
        value.remainSignedIn = remainSignedIn;
        localStorage.setItem('currentUserSession', JSON.stringify(value));
        return true;
      } else {
        return false;
      }
    });
  }

  isLoggedIn(): boolean {
    return !!localStorage.getItem('currentUserSession');
  }

  logout() {
    localStorage.removeItem('currentUserSession');
    this.router.navigateByUrl('/');
  }

  getCurrentUserSession(): UserSession {
    return JSON.parse(localStorage.getItem('currentUserSession')) as UserSession;
  }

  async refreshSessionIfNecessary() {
    if (!this.isLoggedIn()) {
      return;
    }

    const currentUserSession = this.getCurrentUserSession();

    if (this.jwtHelperService.isTokenExpired(currentUserSession.jwtToken, 3)) {
      const reqOpts = {
        headers: {
          Authorization: 'Bearer ' + currentUserSession.jwtToken
        }
      };

      // Request NOT via apiService, because that would cause a stack overflow because of recursion.
      const result: any = await this.http.post(environment.apiUrl + 'api/v1.0/token/refresh',
        { refreshToken: currentUserSession.refreshToken }, reqOpts).toPromise();
      const newSession = new UserSession();
      newSession.refreshToken = result.data.refreshToken;
      newSession.jwtToken = result.data.token;
      newSession.user = new User(result.data.username, result.data.department, result.data.roles);

      localStorage.setItem('currentUserSession', JSON.stringify(newSession));
    }
  }

}
