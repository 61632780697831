import { Component, Input, OnInit } from '@angular/core';
import { Statistic } from 'src/app/_model/statistic';
import { DateConverterService } from 'src/app/_service/date-converter.service';
import { StatisticPeriodEnum } from 'src/app/statistics/statistics.component';

@Component({
  selector: 'app-statistic-card',
  templateUrl: './statistic-card.component.html',
  styleUrls: ['./statistic-card.component.scss']
})
export class StatisticCardComponent implements OnInit {

  @Input() statistic: Statistic;
  @Input() period: StatisticPeriodEnum;

  constructor(private dateConverterService: DateConverterService) { }

  ngOnInit() {
  }

  makeTitle() {
    if (this.period === StatisticPeriodEnum.all) {
      return 'Gesamt';
    } else if (this.period === StatisticPeriodEnum.year) {
      return this.statistic.period;
    } else if (this.period === StatisticPeriodEnum.quarter) {
      const splitted = this.statistic.period.split('-');
      return splitted[1] + '. Quartal ' + splitted[0];
    } else  if (this.period === StatisticPeriodEnum.month) {
      return this.dateConverterService.convertToStringYearMonth(new Date(this.statistic.period));
    }
  }

}
