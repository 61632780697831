import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-svg-step3-inactive',
  template: `
      <svg width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.87793 12.3997C0.87793 6.19707 5.92535 1.14966 12.1279 1.14966C18.3305 1.14966 23.3779 6.19707 23.3779 12.3997C23.3779 18.6022 18.3305 23.6497 12.1279 23.6497C5.92535 23.6497 0.87793 18.6022 0.87793 12.3997ZM1.93108 12.3997C1.93108 18.023 6.50494 22.5963 12.1279 22.5963C17.7509 22.5963 22.3248 18.023 22.3248 12.3997C22.3248 6.77635 17.7509 2.20305 12.1279 2.20305C6.50494 2.20305 1.93108 6.77635 1.93108 12.3997Z" [attr.fill]="fill"/>
              <path d="M12.6422 5.92092V12.9051H18.535C18.8046 12.9051 19.0231 13.1236 19.0231 13.3931C19.0231 13.6626 18.8046 13.8812 18.535 13.8812H12.1541C11.8846 13.8812 11.6661 13.6626 11.6661 13.3931V5.92092C11.6661 5.65141 11.8846 5.43286 12.1541 5.43286C12.4237 5.43286 12.6422 5.65141 12.6422 5.92092Z" [attr.fill]="fill"/>
          </g>
          <defs>
              <clipPath id="clip0">
                  <rect width="24" height="24" fill="white" transform="translate(24.1279 0.399658) rotate(90)"/>
              </clipPath>
          </defs>
      </svg>
  `
})
export class Step3InactiveComponent {
  @Input() fill: string;
}
