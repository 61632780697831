import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-svg-step3-active',
  template: `
      <svg width="24" height="25" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M12.1279 1.14966C5.92535 1.14966 0.87793 6.19707 0.87793 12.3997C0.87793 18.6022 5.92535 23.6497 12.1279 23.6497C18.3305 23.6497 23.3779 18.6022 23.3779 12.3997C23.3779 6.19707 18.3305 1.14966 12.1279 1.14966ZM12.6423 12.9051V5.92092C12.6423 5.65141 12.4237 5.43286 12.1542 5.43286C11.8847 5.43286 11.6661 5.65141 11.6661 5.92092V13.3931C11.6661 13.6626 11.8847 13.8812 12.1542 13.8812H18.5351C18.8046 13.8812 19.0232 13.6626 19.0232 13.3931C19.0232 13.1236 18.8046 12.9051 18.5351 12.9051H12.6423Z"
                [attr.fill]="fill"/>
      </svg>
  `
})
export class Step3ActiveComponent {
  @Input() fill: string;
}
