export class Statistic {
  period: string;
  countTotal: number;
  countSuccess: number;
  countFailed: number;
  countInProgress: number;
  countPaused: number;

  constructor(other: any) {
    this.period          = other.period;
    this.countTotal      = other.countTotal;
    this.countSuccess    = other.countSuccess;
    this.countFailed     = other.countFailed;
    this.countInProgress = other.countInProgress;
    this.countPaused     = other.countPaused;
  }
}
