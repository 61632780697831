import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-svg-step5-failed-active',
  template: `
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M22.6859 9.4485L11.8853 0.546347C11.7058 0.3967 11.4451 0.396636 11.2655 0.5463L0.609568 9.42633C0.499391 9.5181 0.435547 9.65426 0.435547 9.79804V22.2298C0.435547 22.4971 0.6522 22.7138 0.919574 22.7138H12.8033C10.9686 21.4268 9.76929 19.2957 9.76929 16.8844C9.76929 12.9546 12.9551 9.7688 16.8849 9.7688C19.3948 9.7688 21.6011 11.0683 22.8679 13.0311V9.83433C22.8679 9.685 22.8012 9.54348 22.6859 9.4485Z" [attr.fill]="fill"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8724 23.1328C20.3303 23.1328 23.1334 20.3296 23.1334 16.8718C23.1334 13.4139 20.3303 10.6107 16.8724 10.6107C13.4145 10.6107 10.6113 13.4139 10.6113 16.8718C10.6113 20.3296 13.4145 23.1328 16.8724 23.1328ZM19.7001 19.7001C19.5049 19.8954 19.1883 19.8954 18.993 19.7001L16.8717 17.5788L14.7504 19.7001C14.5551 19.8954 14.2385 19.8954 14.0433 19.7001C13.848 19.5049 13.848 19.1883 14.0433 18.993L16.1646 16.8717L14.0433 14.7504C13.848 14.5551 13.848 14.2385 14.0433 14.0433C14.2385 13.848 14.5551 13.848 14.7504 14.0433L16.8717 16.1646L18.993 14.0433C19.1883 13.848 19.5049 13.848 19.7001 14.0433C19.8954 14.2385 19.8954 14.5551 19.7001 14.7504L17.5788 16.8717L19.7001 18.993C19.8954 19.1883 19.8954 19.5049 19.7001 19.7001Z" [attr.fill]="fill"/>
      </svg>
  `
})
export class Step5FailedActiveComponent {
  @Input() fill: string;
}
