import {ShipmentHistory} from 'src/app/_model/shipment-history';
import {ShipmentStatus} from 'src/app/_model/shipment-status.enum';

export class ShipmentDetail {
  id: number;
  date: Date;
  receiver: string;
  status: ShipmentStatus;
  description: string;
  shipmentNumber: string;
  history: Array<ShipmentHistory>;
  latestHistoryPerStep: Array<ShipmentHistory>;
  preDeliveryHistory: Array<ShipmentHistory>;
  step: number;
  color: string;
  trackingToken: string;
  estimatedDelivery: Date;
}
