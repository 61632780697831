import { Injectable } from '@angular/core';
import { ShipmentOverview } from 'src/app/_model/shipment-overview';
import { ShipmentStatus } from 'src/app/_model/shipment-status.enum';
import { ApiService } from 'src/app/_service/api.service';
import { DateConverterService } from 'src/app/_service/date-converter.service';

@Injectable({
  providedIn: 'root'
})
export class ShipmentOverviewService {

  constructor(private apiService: ApiService, private dateConverterService: DateConverterService) { }

  public getShipmentOverviews(): Promise<void | Array<ShipmentOverview>> {
    return this.apiService.get('api/v1.0/shipment').then(response => {
      return response.data.map(item => {
        const result = new ShipmentOverview();
        result.id = item.id;
        result.receiver = item.receiver;
        result.shipmentNumber = item.shipmentNumber;
        result.date = this.dateConverterService.convertFromString(item.estimatedDelivery);
        result.color = item.color;
        result.status = ShipmentStatus.forString(item.status);
        result.description = item.description;
        return result;
      });
    });
  }

}
