export enum ShipmentStatus {
  RUNNING   = 'Die Sendung befindet sich in Zustellung',
  PAUSED    = 'PAUSED',
  CANCELED  = 'CANCELED',
  COMPLETED = 'COMPLETED'
}

// tslint:disable-next-line:no-namespace
export namespace ShipmentStatus {
  export function forString(stateString: string) {
    switch (stateString) {
      case 'running':
        return ShipmentStatus.RUNNING;
      case 'paused':
        return ShipmentStatus.PAUSED;
      case 'canceled':
        return ShipmentStatus.CANCELED;
      case 'completed':
        return ShipmentStatus.COMPLETED;
    }
  }

  export function sortKey(shipmentStatus: ShipmentStatus) {
    switch (shipmentStatus) {
      case ShipmentStatus.RUNNING:
        return 0;
      case ShipmentStatus.PAUSED:
        return 1;
      case ShipmentStatus.CANCELED:
        return 2;
      case ShipmentStatus.COMPLETED:
        return 3;

    }
  }
}
