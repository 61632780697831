import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-svg-step1-active',
  template: `
      <svg width="24" height="25" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0)">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M13.9173 2.79245C13.8357 2.79245 13.7629 2.74102 13.7356 2.66407C13.4822 1.94936 12.7992 1.43677 12 1.43677C11.2007 1.43677 10.5177 1.94936 10.2643 2.66407C10.237 2.74102 10.1642 2.79245 10.0826 2.79245H3.86288C3.59667 2.79245 3.38086 3.00825 3.38086 3.27446V23.706C3.38086 23.9722 3.59667 24.188 3.86288 24.188H20.137C20.4032 24.188 20.6191 23.9722 20.6191 23.706V3.27446C20.6191 3.00825 20.4032 2.79245 20.137 2.79245H13.9173ZM12.8021 2.93886C12.6698 2.62642 12.3601 2.40674 11.9999 2.40674C11.5197 2.40674 11.1293 2.79721 11.1293 3.27738C11.1293 3.5436 10.9135 3.76242 10.6473 3.76242H8.89994C8.63373 3.76242 8.41792 3.97822 8.41792 4.24443V5.02169C8.41792 5.08098 8.42863 5.13777 8.44821 5.19023C8.51653 5.37337 8.69302 5.5038 8.89997 5.5038H15.0999C15.3662 5.5038 15.582 5.28799 15.582 5.02178V4.24453C15.582 4.18524 15.5713 4.12844 15.5517 4.07598C15.4834 3.89284 15.3069 3.76242 15.0999 3.76242H13.3526C13.1457 3.76242 12.9692 3.63021 12.9009 3.44631C12.8813 3.39364 12.8706 3.33673 12.8706 3.27748C12.8706 3.19225 12.8583 3.10984 12.8354 3.03195C12.826 3.00013 12.8149 2.96906 12.8021 2.93886ZM6.69772 9.83308C6.43151 9.83308 6.2157 10.0489 6.2157 10.3151C6.2157 10.5813 6.43151 10.7971 6.69772 10.7971H17.3021C17.5683 10.7971 17.7841 10.5813 17.7841 10.3151C17.7841 10.0489 17.5683 9.83308 17.3021 9.83308H6.69772ZM6.2157 14.1713C6.2157 13.905 6.43151 13.6892 6.69772 13.6892H17.3021C17.5683 13.6892 17.7841 13.905 17.7841 14.1713C17.7841 14.4375 17.5683 14.6533 17.3021 14.6533H6.69772C6.43151 14.6533 6.2157 14.4375 6.2157 14.1713ZM6.69772 17.5454C6.43151 17.5454 6.2157 17.7612 6.2157 18.0274C6.2157 18.2936 6.43151 18.5094 6.69772 18.5094H17.3021C17.5683 18.5094 17.7841 18.2936 17.7841 18.0274C17.7841 17.7612 17.5683 17.5454 17.3021 17.5454H6.69772Z"
                    [attr.fill]="fill"/>
          </g>
          <defs>
              <clipPath id="clip0">
                  <rect width="24" height="24" fill="white" transform="translate(0 0.8125)"/>
              </clipPath>
          </defs>
      </svg>

  `
})
export class Step1ActiveComponent {
  @Input() fill: string;
}
