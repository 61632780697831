import {Injectable} from '@angular/core';
import {ShipmentDetail} from 'src/app/_model/shipment-detail';
import {ShipmentHistory} from 'src/app/_model/shipment-history';
import {ShipmentStatus} from 'src/app/_model/shipment-status.enum';
import {ApiService} from 'src/app/_service/api.service';
import {DateConverterService} from 'src/app/_service/date-converter.service';

@Injectable({
  providedIn: 'root'
})
export class ShipmentDetailService {

  constructor(private apiService: ApiService, private dateConverterService: DateConverterService) {
  }

  public getShipmentDetail(shipmentNumber: string): Promise<ShipmentDetail> {
    return this.apiService.get('api/v1.0/shipment/detail/' + shipmentNumber).then(result => this.mapResponse(result));
  }

  public getShipmentDetailByToken(shipmentToken: string): Promise<ShipmentDetail> {
    return this.apiService.get('api/v1.0/shipment/detail/token/' + shipmentToken).then(result => this.mapResponse(result));
  }

  private mapResponse(result) {
    if (!result.success) {
      throw new Error(result.error.message);
    }
    const typed          = new ShipmentDetail();
    typed.id             = result.data.id;
    typed.status         = ShipmentStatus.forString(result.data.status);
    typed.description    = result.data.description;
    typed.receiver       = result.data.receiver;
    typed.shipmentNumber = result.data.shipmentNumber;
    typed.step           = result.data.step;
    typed.color          = result.data.color;
    typed.trackingToken  = result.data.trackingToken;

    typed.date              = this.dateConverterService.convertFromString(result.data.date);
    typed.estimatedDelivery = this.dateConverterService.convertFromString(result.data.estimatedDelivery);

    typed.history              = this.mapHistory(result.data.history);
    typed.preDeliveryHistory   = this.mapHistory(result.data.preDeliveryHistory);
    typed.latestHistoryPerStep = this.mapHistory(result.data.latestHistoryPerStep);
    return typed;
  }

  private mapHistory(data) {
    if (data === null) {
      return [];
    }

    return data.map(untyped => {
      const history       = new ShipmentHistory();
      history.date        = this.dateConverterService.convertFromString(untyped.date);
      history.description = untyped.description;
      history.step        = untyped.step;
      history.externalId  = untyped.externalId;
      return history;
    });
  }
}
