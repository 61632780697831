import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Step1ActiveComponent } from 'src/app/_svg/step1-active.component';
import { Step1InactiveComponent } from 'src/app/_svg/step1-inactive.component';
import { Step2ActiveComponent } from 'src/app/_svg/step2-active.component';
import { Step2InactiveComponent } from 'src/app/_svg/step2-inactive.component';
import { Step3ActiveComponent } from 'src/app/_svg/step3-active.component';
import { Step3InactiveComponent } from 'src/app/_svg/step3-inactive.component';
import { Step4ActiveComponent } from 'src/app/_svg/step4-active.component';
import { Step4InactiveComponent } from 'src/app/_svg/step4-inactive.component';
import { Step5ActiveComponent } from 'src/app/_svg/step5-active.component';
import { Step5FailedActiveComponent } from 'src/app/_svg/step5-failed-active.component';
import { Step5InactiveComponent } from 'src/app/_svg/step5-inactive.component';

@NgModule({
  declarations: [
    Step1ActiveComponent,
    Step2ActiveComponent,
    Step3ActiveComponent,
    Step4ActiveComponent,
    Step5ActiveComponent,
    Step5FailedActiveComponent,
    Step1InactiveComponent,
    Step2InactiveComponent,
    Step3InactiveComponent,
    Step4InactiveComponent,
    Step5InactiveComponent,
  ],
  exports: [
    Step1ActiveComponent,
    Step2ActiveComponent,
    Step3ActiveComponent,
    Step4ActiveComponent,
    Step5ActiveComponent,
    Step5FailedActiveComponent,
    Step1InactiveComponent,
    Step2InactiveComponent,
    Step3InactiveComponent,
    Step4InactiveComponent,
    Step5InactiveComponent,
  ],
  imports: [
    CommonModule
  ]
})
export class SvgModule { }
