import { Injectable } from '@angular/core';
import { Statistic } from 'src/app/_model/statistic';
import { ApiService } from 'src/app/_service/api.service';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  constructor(private apiService: ApiService) { }

  public getStatistics(period: string, amount: number): Promise<void | Array<Statistic>> {
    const reqOpts = {
      params: {
        period,
        amount
      }
    };
    return this.apiService.get('api/v1.0/shipment/statistic', reqOpts).then(data => {
        return data.data.map(item => new Statistic(item));
    });
  }

}
