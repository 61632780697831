import {registerLocaleData} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {NgbDropdownModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import localeDe from '@angular/common/locales/de';
import {NgxPaginationModule} from 'ngx-pagination';
import {SvgModule} from 'src/app/_svg/svg.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {WelcomeComponent} from './welcome/welcome.component';
import {LoginComponent} from './login/login.component';
import {ShipmentsComponent} from './shipments/shipments.component';
import {ShipmentOverviewComponent} from 'src/app/shipment-status/shipment-overview.component';
import {StatisticsComponent} from './statistics/statistics.component';
import {StatisticCardComponent} from 'src/app/statistic/statistic-card.component';
import {ShipmentDetailComponent} from './shipment-detail/shipment-detail.component';
import {PrivacyImprintFooterComponent} from 'src/app/private-imprint-footer/privacy-imprint-footer.component';
import {PrivacyComponent} from './privacy/privacy.component';
import {ImprintComponent} from './imprint/imprint.component';
import {LongDatePipe} from 'src/app/_pipes/long-date/long-date.pipe';
import {MultiselectDropdownModule} from 'angular-2-dropdown-multiselect';

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    LoginComponent,
    ShipmentsComponent,
    ShipmentOverviewComponent,
    StatisticsComponent,
    StatisticCardComponent,
    ShipmentDetailComponent,
    PrivacyImprintFooterComponent,
    PrivacyComponent,
    ImprintComponent,
    LongDatePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
    SvgModule,
    NgxPaginationModule,
    MultiselectDropdownModule,
    NgbDropdownModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de' }
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule {
  constructor() {
    registerLocaleData(localeDe);
  }
}
