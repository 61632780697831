import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateConverterService {
  months = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
  daysOfWeek = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];

  constructor() { }

  convertToString(date: Date): string {
    const dayOfWeek = this.daysOfWeek[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = this.months[date.getMonth()];
    const year = date.getFullYear();
    const hourOfDay = date.getHours();
    const minuteOfHour = date.getMinutes();

    return dayOfWeek + ', ' + dayOfMonth + '. ' + month + ' ' + year; // + ', ' + hourOfDay + ':' + minuteOfHour;
  }

  convertToStringYearMonth(date: Date): string {
    const month = this.months[date.getMonth()];
    const year = date.getFullYear();

    return month + ' ' + year;
  }

  /**
   * The Backend sends Date including the Timezone Offset. This cannot be parsed by Javascript by default.
   */
  convertFromString(dateString: string): Date {
    const withoutTimezone = dateString.substring(0, dateString.indexOf('+'));
    return new Date(withoutTimezone);
  }
}
