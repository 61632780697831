import {AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbCalendar, NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts} from 'angular-2-dropdown-multiselect';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ShipmentOverview} from 'src/app/_model/shipment-overview';
import {ShipmentStatus} from 'src/app/_model/shipment-status.enum';
import {User} from 'src/app/_model/user';
import {AuthenticationService} from 'src/app/_service/authentication.service';
import {ShipmentOverviewService} from 'src/app/_service/shipment-overview.service';
import {ShipmentsFilterStatusService} from 'src/app/_service/shipments-filter-status.service';

@Component({
  selector: 'app-shipments',
  templateUrl: './shipments.component.html',
  styleUrls: [ './shipments.component.scss' ]
})
export class ShipmentsComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('searchQueryField', { static: false }) searchQueryField;
  @ViewChild('datepickerPopup', { static: false }) datepickerPopup;
  @ViewChild('datepickerButton', { static: false }) datepickerButton;

  user: User;
  hoveredDate: NgbDate;
  fromDate: NgbDate;
  toDate: NgbDate;
  filterString: string;
  errorMessage: string;

  private searchUpdated                         = new Subject();
  allShipmentOverviews: Array<ShipmentOverview> = [];
  shipmentOverviews: Array<ShipmentOverview>    = [];
  isLoading                                     = true;
  datePickerValue                               = 'Datum';
  datepickerOpen                                = false;
  datepickerHover                               = false;
  page                                          = 1;
  statusFilterOptions: Array<ShipmentStatus>    = [];
  allStatusFilterOptions: IMultiSelectOption[]  = [
    { id: ShipmentStatus.RUNNING, name: 'Lieferung ausstehend' },
    { id: ShipmentStatus.PAUSED, name: 'Pausiert' },
    { id: ShipmentStatus.CANCELED, name: 'Abgebrochen' },
    { id: ShipmentStatus.COMPLETED, name: 'Ausgeliefert' }
  ];

  statesSelectFilterSettings: IMultiSelectSettings = {
    pullRight: true,
    enableSearch: false,
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-sm btn-light',
    dynamicTitleMaxItems: 0,
    displayAllSelectedText: true
  };

  statesSelectTexts: IMultiSelectTexts = {
    checkAll: 'Alle auswählen',
    uncheckAll: 'Auswahl löschen',
    checked: 'Status selektiert',
    checkedPlural: 'Status selektiert',
    searchPlaceholder: 'Suchen',
    searchEmptyResult: 'Kein Eintrag gefunden',
    searchNoRenderText: '',
    defaultTitle: 'Sendungsstatus',
    allSelected: 'Alle Status',
  };

  constructor(calendar: NgbCalendar,
              private shipmentOverviewService: ShipmentOverviewService,
              private authenticationService: AuthenticationService,
              private filterService: ShipmentsFilterStatusService
  ) {
    if (filterService.fromDate === undefined || filterService.fromDate === null) {
      this.fromDate = calendar.getToday();
    } else {
      this.fromDate = filterService.fromDate;
    }

    if (filterService.toDate === undefined || filterService.toDate === null) {
      this.toDate = calendar.getNext(calendar.getToday(), 'd', 3);
    } else {
      this.toDate = filterService.toDate;
    }

    this.datePickerValue = this.convertNgbDateToString(this.fromDate) + ' - ' + this.convertNgbDateToString(this.toDate);

    if (filterService.searchQuery !== undefined && filterService.searchQuery !== null) {
      this.filterString = filterService.searchQuery;
    }
  }

  ngOnInit() {
    this.shipmentOverviewService.getShipmentOverviews().then(result => {
      if (result) {
        this.allShipmentOverviews = result;
        this.applyFilterAndSort();
      }
    }).catch(_ => {
      this.errorMessage = 'Bei der Abfrage der Sendungen ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.';
    })
      .finally(() => this.isLoading = false);
    this.user = this.authenticationService.getCurrentUserSession().user;

    this.searchUpdated.asObservable().pipe(debounceTime(500), distinctUntilChanged()).subscribe(_ => {
      this.applyFilterAndSort();
    });

    this.statusFilterOptions = [
      ShipmentStatus.RUNNING,
      ShipmentStatus.PAUSED,
      ShipmentStatus.CANCELED,
      ShipmentStatus.COMPLETED
    ];
  }

  ngAfterViewInit() {
    this.datePickerValue = this.convertNgbDateToString(this.fromDate) + ' - ' + this.convertNgbDateToString(this.toDate);

    if (this.filterService.searchQuery !== undefined && this.filterService.searchQuery !== null) {
      this.searchQueryField.nativeElement.value = this.filterService.searchQuery;
    } else {
      this.searchQueryField.nativeElement.value = '';
    }
  }

  ngOnDestroy(): void {
    this.filterService.fromDate    = this.fromDate;
    this.filterService.toDate      = this.toDate;
    this.filterService.searchQuery = this.searchQueryField.nativeElement.value;
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate        = date;
      this.datePickerValue = this.convertNgbDateToString(this.fromDate);
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate          = date;
      this.datePickerValue = this.convertNgbDateToString(this.fromDate) + ' - ' + this.convertNgbDateToString(this.toDate);
    } else {
      this.toDate          = null;
      this.fromDate        = date;
      this.datePickerValue = this.convertNgbDateToString(this.fromDate);
    }

    this.applyFilterAndSort();
  }

  convertNgbDateToString(date: NgbDate): string {
    if (date === undefined || date === null) {
      return '';
    }
    return date.day + '.' + date.month + '.' + date.year;
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  logout() {
    this.authenticationService.logout();
  }

  private applyFilterAndSort() {
    this.shipmentOverviews = this.allShipmentOverviews.filter(item => {
      if (this.fromDate && !this.toDate) {
        let result = item.date.getFullYear() === this.fromDate.year;
        result     = result && item.date.getMonth() === this.fromDate.month - 1;
        result     = result && item.date.getDate() === this.fromDate.day;
        return result;
      } else if (this.fromDate && this.toDate) {
        const fromDate = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
        const toDate   = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day);
        return fromDate <= item.date && item.date <= toDate;
      } else {
        return true;
      }
    }).filter(item => {
      if (this.filterString === null || this.filterString === undefined) {
        return true;
      }

      return item.receiver.includes(this.filterString) || item.shipmentNumber.includes(this.filterString);
    }).filter(item => {
      return this.statusFilterOptions.includes(item.status);
    });

    this.shipmentOverviews = this.shipmentOverviews.sort((a, b) => {
      return (b.date as any) - (a.date as any);
    });
  }

  private onSearchType() {
    this.searchUpdated.next(this.filterString);
  }

  clearDateField() {
    this.fromDate        = null;
    this.toDate          = null;
    this.datePickerValue = 'Datum';
    this.applyFilterAndSort();
  }

  onStatusFilterOptionChanged() {
    this.applyFilterAndSort();
  }

  @HostListener('document:click', [ '$event' ])
  checkClickOutOfDatepicker(event) {
    if (this.datepickerOpen &&
      !this.datepickerPopup._elementRef.nativeElement.contains(event.target) &&
      !this.datepickerButton.nativeElement.contains(event.target)) {
      this.datepickerOpen = false;
    }
  }
}
