import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ShipmentDetail} from 'src/app/_model/shipment-detail';
import {DateConverterService} from 'src/app/_service/date-converter.service';
import {ShipmentDetailService} from 'src/app/_service/shipment-detail.service';

@Component({
  selector: 'app-shipment-detail',
  templateUrl: './shipment-detail.component.html',
  styleUrls: [ './shipment-detail.component.scss' ]
})
export class ShipmentDetailComponent implements OnInit {

  shipmentDetail: ShipmentDetail;
  isLoading        = true;
  defaultColor     = '#8E8E93';
  activeColor      = '#8E8E93';
  stepsDescription = [ '', '', '', '', '', '', '', '', '' ];
  alertMessage: string;

  constructor(private shipmentDetailService: ShipmentDetailService,
              private dateConverterService: DateConverterService,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    const path = this.activatedRoute.snapshot.routeConfig.path;
    if (path === 'shipment-detail/:shipmentNumber') {
      const shipmentId = this.activatedRoute.snapshot.paramMap.get('shipmentNumber');
      this.shipmentDetailService.getShipmentDetail(shipmentId).then(result => this.mapResult(result))
        .catch(error => {
          this.isLoading    = false;
          this.alertMessage = 'Bei der Abfrage der Sendung ist ein Problem aufgetreten. Bitte versuchen sie es später erneut.';
        });
    } else if (path === 'shipment-tracking/:shipmentToken') {
      const shipmentToken = this.activatedRoute.snapshot.paramMap.get('shipmentToken');
      this.shipmentDetailService.getShipmentDetailByToken(shipmentToken).then(result => this.mapResult(result))
        .catch(error => {
          this.isLoading    = false;
          this.alertMessage = 'Bei der Abfrage der Sendung ist ein Problem aufgetreten. Bitte versuchen sie es später erneut.';
        });
    } else {
      this.isLoading    = false;
      this.alertMessage = 'Bei der Abfrage der Sendung ist ein Problem aufgetreten. Bitte versuchen sie es später erneut.';
    }
  }

  private mapResult(result) {
    this.shipmentDetail = result;

    this.shipmentDetail.latestHistoryPerStep.forEach(untyped => {
      this.stepsDescription[untyped.step - 1] = untyped.description;
    });

    this.activeColor = this.shipmentDetail.color;
    this.isLoading   = false;
  }

  print() {
    window.print();
  }

  copyTrackingLink(trackingToken: string) {
    const trackingURL     = window.location.origin + '/shipment-tracking/' + trackingToken;
    const selBox          = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left     = '0';
    selBox.style.top      = '0';
    selBox.style.opacity  = '0';
    selBox.value          = trackingURL;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
