import { Component, HostListener } from '@angular/core';
import { AuthenticationService } from 'src/app/_service/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'westfalen-medical-frontend';

  constructor(private authenticationService: AuthenticationService) {}

  @HostListener('window:onbeforeunload', ['$event'])
  clearLocalStorage(event) {
    const userSession = this.authenticationService.getCurrentUserSession();
    if (!userSession.remainSignedIn) {
      localStorage.clear();
    }
  }
}
