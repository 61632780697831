import { Injectable } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class ShipmentsFilterStatusService {

  searchQuery: string;
  toDate: NgbDate;
  fromDate: NgbDate;

  constructor() { }
}
