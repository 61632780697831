import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-private-imprint-footer',
  templateUrl: './privacy-imprint-footer.component.html',
  styleUrls: ['./privacy-imprint-footer.component.scss']
})
export class PrivacyImprintFooterComponent implements OnInit {

  @Input() backgroundColor = '#FAFAFA';

  @Input() fontColor = 'rgba(0, 0, 0, 0.6)';

  constructor() { }

  ngOnInit() {
  }

}
