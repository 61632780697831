import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/_service/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('f', { static: false }) loginForm: NgForm;
  alertMessage = '';

  constructor(private authenticationService: AuthenticationService, private router: Router) {
  }

  ngOnInit() {
  }

  onSubmit() {
    this.authenticationService.login(
      this.loginForm.value.loginData.prescribersNumber,
      this.loginForm.value.loginData.password,
      this.loginForm.value.loginData.remainSignedIn
    ).then(_ => {
      this.router.navigateByUrl('/shipments');
    }).catch(error => {
      this.alertMessage = error.message;

      this.loginForm.form.patchValue({
        loginData: {
          prescribersNumber: '',
          password: ''
        }
      });
    });
  }
}
