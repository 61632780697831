import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-svg-step2-inactive',
  template: `
      <svg width="24" height="25" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.0805 11.1154L17.1024 14.2125C17.1716 14.4222 17.0576 14.6484 16.8479 14.7176C16.6381 14.7868 16.412 14.6728 16.3428 14.4631L15.3207 11.3654L14.7817 12.5404C14.6988 12.7212 14.4849 12.8004 14.3042 12.7173C14.1238 12.6342 14.0448 12.4208 14.1276 12.2403L15.0202 10.2955C15.1359 10.0434 15.4414 9.94261 15.6844 10.0763L17.5595 11.1079C17.7335 11.2037 17.797 11.4223 17.7014 11.5964C17.6057 11.7707 17.3867 11.8344 17.2125 11.7385L16.0805 11.1154Z" [attr.fill]="fill"/>
          <path d="M6.60769 3.53155L11.3939 18.0376C11.0351 17.9716 10.6628 17.9633 10.2897 18.0176L5.61791 3.85816C5.3949 3.18225 4.66402 2.81404 3.98814 3.03704C3.31223 3.26004 2.94403 3.99093 3.16702 4.6668C3.28509 5.0252 2.99514 5.25493 2.8354 5.325C2.5621 5.41516 2.26739 5.26668 2.1772 4.99337C1.77426 3.77209 2.44011 2.45023 3.66154 2.04722C4.88296 1.64421 6.20475 2.31027 6.60769 3.53155Z" [attr.fill]="fill"/>
          <path d="M13.4827 19.3063L21.2551 16.7418C21.5283 16.6516 21.8231 16.8001 21.9133 17.0735C22.0035 17.3467 21.855 17.6415 21.5817 17.7316L13.9933 20.2354C13.8695 19.8926 13.6956 19.581 13.4827 19.3063Z" [attr.fill]="fill"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1213 20.6235C12.6945 19.3298 11.2997 18.6271 10.006 19.054C8.71227 19.4808 8.0095 20.8756 8.43638 22.1693C8.86325 23.4631 10.258 24.1658 11.5518 23.7389C12.8454 23.312 13.5482 21.9173 13.1213 20.6235ZM9.38135 21.8331C9.63195 22.5926 10.4509 23.0052 11.2104 22.7547C11.97 22.504 12.3826 21.6851 12.132 20.9255C11.8814 20.166 11.0625 19.7534 10.3029 20.004C9.54333 20.2546 9.13074 21.0735 9.38135 21.8331Z" [attr.fill]="fill"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M21.902 14.6625L19.1957 6.46006C19.1382 6.28582 18.9503 6.19121 18.7761 6.24863L10.2582 9.05908C10.0839 9.11657 9.98929 9.30441 10.0468 9.47864L12.7531 17.6811C12.8106 17.8553 12.9984 17.95 13.1726 17.8925L21.6906 15.0821C21.8648 15.0246 21.9595 14.8367 21.902 14.6625ZM18.4838 7.35081L11.149 9.77091L13.4649 16.7903L20.7998 14.3702L18.4838 7.35081Z" [attr.fill]="fill"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8661 2.14631L9.08584 3.3845L10.3033 7.10133L14.0835 5.86314L12.8661 2.14631ZM8.33473 2.6255C8.08409 2.70759 7.94745 2.97733 8.02955 3.22797L9.54424 7.85245C9.62634 8.10309 9.89607 8.23972 10.1467 8.15763L14.8347 6.62215C15.0853 6.54005 15.2219 6.27032 15.1398 6.01968L13.6251 1.39519C13.5431 1.14455 13.2733 1.00792 13.0227 1.09001L8.33473 2.6255Z" [attr.fill]="fill"/>
      </svg>
  `
})
export class Step2InactiveComponent {
  @Input() fill: string;
}
